.data-table {
  margin: 20px 0;
  position: relative;
}

.data-table__title {
  padding: 10px 15px;
}

.data-table__progress {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 20px);
}

.data-table__table {
  display: block !important;
  width: 100%;
  overflow-x: auto;

  td, th {
    &:first-child:not(:empty) {
      width: 100%;
      position: sticky;
      background-color: #fff;
      left: 0;
      box-shadow: inset -1px 0 0 #e0e0e0;
      z-index: 1;
    }
  }

  td {
    transition: background-color .15s ease;
  }
  tr:hover > td{
    background-color: #f4f4f4 !important;
  }
}
