.data-card {
  position: relative;
  height: 100%;
  max-height: 321px;
  overflow-y: auto;
}

.data-card__title {
  padding: 10px 15px;
}

.data-card__progress {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.data-card__row {
  padding: 5px 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    padding-bottom: 15px;
    border-bottom: none;
  }
}

.data-card__label {
  font-weight: 700;
  padding-right: 10px;
}

.data-card__value {
  font-weight: 300;
  font-size: 0.86em;

  &[href] {
    color: #000;
  }
}
