.app {
  padding: 20px;
}

.app__header {
  display: flex;
  align-items: center;
}

.app__logo {
  vertical-align: middle;
  padding-right: 10px;
}

.app__title {
  font-size: 22px !important;
  font-weight: 600 !important;
}
